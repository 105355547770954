import React, { useState } from 'react'
import useBreakpoint from "~utils/useBreakpoint"
import ProductBadge from "~components/global/productBadge"
import ProductBadgeMini from "~components/global/productBadgeMini"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import { X } from "~components/global/svg"
import { SelectButton } from "~components/global/formElements"
import resolveLink from "~utils/resolveLink"
import { dlSelectItem } from '../../utils/dlFunctions'

const ProductVariants = ({ varTitle, currentVariant, variants, currentTitle, productSlug }) => {
  const [overlay, setOverlay] = useState(false)
  const { mobile, tablet } = useBreakpoint()

  if(!currentVariant.main.basic){
    return null
  }

  return(
    <>
      <Helmet
      bodyAttributes={overlay && {
        class: 'no-scroll'
      }}
      htmlAttributes={overlay && {
        class: 'no-scroll'
      }}
      />

      {tablet &&
      <div className="product-types-compact">
        <h4 className="product-info__heading">{varTitle}</h4>
        <div className="product-types__section">
          <h5>Choose your flavour</h5>
          <SelectButton
            primaryIcon={
              <ProductBadgeMini
            colors={currentVariant.main.basic ? currentVariant.main.basic.iconColors.map(c => c.color ? c.color.hex : 'null') : []} />}
            onClick={() => setOverlay('product-types')}>
            {currentVariant._rawShopify.variantTitle}
          </SelectButton>
        </div>
      </div>
      }
      <div className={`product-types ${overlay === 'product-types' && tablet ? 'product-types--visible' : '' }`}>
        {tablet &&
          <button className="product-types__close" onClick={() => setOverlay(false)}><X /></button>
        }
        <h4 className="product-info__heading">{varTitle}</h4>
        <div className="product-types__section">
          <div className="product-types__sub-section">
            <div className="product-types__products">
              {variants.map((variant, index) => (
                <Link 
                  to={`/extras/${productSlug}/${variant.content.main.slug.current}`} key={variant.content.main.slug && variant.content.main.slug.current}
                  onClick={() => dlSelectItem(variant?.content, index + 1, 'Extras', variant)}
                >
                  <ProductBadge
                    name={variant.content._rawShopify.variantTitle}
                    colors={variant.content.main.basic && variant.content.main.basic.iconColors.map(c => c.color.hex)}
                    rating={variant.content.main.basic && variant.content.main.basic.strength}
                    active={variant.content._rawShopify.variantTitle === currentVariant._rawShopify.variantTitle}/>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ProductVariants
