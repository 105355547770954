import React, { Children } from 'react'
import Slider from "~components/global/slider"

const ProductImages = ({ title, images, children, style }) => (
  <div className="product-images" style={style}>
    <h4 className="product-info__heading">{title}</h4>
    <div className="basic-slider">
      <Slider arrows="serif" nums={true} preventDrag={true}>
        {Children.map(children, c => (
          <div className="product-images__slide">
            {c}
          </div>
        ))}
      </Slider>
    </div>
  </div>
)

export default ProductImages
