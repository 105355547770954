import React from 'react'

const ProductBadgeMini = ({ colors }) => {
  return(
    <svg viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="50" fill={colors[0]} />
      <circle cx="50" cy="50" r="35" fill={colors[1]} />
    </svg>
  )
}

export default ProductBadgeMini
