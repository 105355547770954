import React from 'react'

const ProductMeta = ({ title, desc, children, scrollToDetail }) => (
  <>
    <h4 className="product-info__heading">{title}</h4>
    <div className="product-meta__desc">
      <h5 className="s-caps">{desc}</h5>
      {children}
      {scrollToDetail &&
        <button onClick={e => scrollToDetail(e)} className="pill pill--orange">Learn More</button>
      }
    </div>
  </>
)

export default ProductMeta
