import React, { useState } from 'react'
import useBreakpoint from "~utils/useBreakpoint"

const ProductIcon = ({ colors }) => {
  if(colors?.length === 0){
    colors = ['#000000', '#ffffff']
  }
  if(colors?.length === 2){
    return(
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" fill={colors[0]} />
        <circle cx="50" cy="50" r="43.5" fill={colors[1]} />
      </svg>
    )
  }
  else{
    return(
      <svg viewBox="0 0 100 100">
        {colors?.map((c, i) => (
          <circle cx="50" cy="50" r={50 - (i * 6.5)} fill={c} />
        ))}
      </svg>
    )
  }
}

const ProductBadge = ({ name, colors, rating, active, onHover }) => {
  const { tablet } = useBreakpoint()
  const [isHovering, setIsHovering] = useState(false)
  const handleMouseEnter = () => {
    if(onHover && !tablet){
      setIsHovering(true)
      onHover()
    }
    return null
  }
  return(
    <div className={active ? "product-badge product-badge--active" : "product-badge"} onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => setIsHovering(false)}>
      <div className="product-badge__icon">
        <ProductIcon colors={colors} />
        {rating && ( !onHover || !isHovering ) &&
          <span className="product-badge__rating" style={{color: colors[0]}}>{rating}</span>
        }
        {onHover && isHovering &&
          <span className="product-badge__plus" style={{color: colors[0]}}>+</span>
        }
      </div>
      <h6 className="xs-serif">{name}</h6>
    </div>
  )
}

export default ProductBadge
