const formatSafeColors = main => {
  let primary = main.primaryColor ? main.primaryColor.hex : '#000000'
  let secondary = main.secondaryColor ? main.secondaryColor.hex : '#ffffff'

  primary = main.primaryColorOverride ? main.primaryColorOverride.hex : primary
  secondary = main.secondaryColorOverride ? main.secondaryColorOverride.hex : secondary

  return [primary, secondary]
}

const formatColors = main => {
  if(!main){
    return {
      base: ['#000000', '#ffffff'],
      safe: ['#000000', '#ffffff'],
      highlight: '#ffffff',
    }
  }
  let colors = {
    base: [main.primaryColor ? main.primaryColor.hex : '#000000', main.secondaryColor ? main.secondaryColor.hex : '#ffffff'],
    safe: formatSafeColors(main),
    highlight: main.highlight ? main.highlight.hex : '#ffffff',
  }
  return colors
}

export default formatColors
