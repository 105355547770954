import { userProperties, prepareProductForTracking } from "../context/siteContext";

export const dlSelectItem = (content, position, category, variant) => {
    const data = {
        event: "dl_select_item",
        user_properties: userProperties,
        ecommerce: {
            currencyCode: "AUD",
            click: {
                actionField: {
                    list: window.location.pathname,
                    action: 'click'
                }, // this should be the collection page URL
                products: [
                    {
                        id: content?._rawShopify?.defaultVariant?.sku ?? content?._rawShopify?.sku, // SKU
                        name: content?._rawShopify?.title, // Product title
                        brand: "Pod & Parcel",
                        // Not easy to weave category in here, so doing a workaround
                        category: category ?? "Unknown",
                        variant: variant ? variant?.content?._rawShopify?.variantTitle : content?._rawShopify?.defaultVariant?.title,
                        price: variant ? variant?.content?._rawShopify?.price : content?._rawShopify?.defaultVariant?.price,
                        list: window.location.pathname, // Not required for dl_view_cart; The list the product is displayed in
                        product_id: content?._rawShopify?.productId.toString(), // The product_id
                        variant_id: variant ? variant?.content?._rawShopify?.variantId.toString() : content?._rawShopify?.defaultVariant?.variantId.toString(),// id or variant_id
                        image: content?.fluid?.src,
                        position
                    }
                ] // See the products array below
            }
        },
      }
    
    window.ElevarDataLayer.push({...data});
}

export const dlViewItem = (content, category, variant) => {
    console.log(content)
    const data = {
        event: "dl_view_item",
        user_properties: userProperties,
        ecommerce: {
            currencyCode: "AUD",
            detail: {
                actionField: {
                    list: window.location.pathname,
                    action: 'click'
                }, // this should be the collection page URL
                products: [
                    {
                        id: content?._rawShopify?.defaultVariant?.sku ?? content?._rawShopify?.sku, // SKU
                        name: content?._rawShopify?.title, // Product title
                        brand: "Pod & Parcel",
                        // Not easy to weave category in here, so doing a workaround
                        category: category ?? "Unknown",
                        variant: variant ? variant?.content?._rawShopify?.variantTitle : content?._rawShopify?.defaultVariant?.title,
                        price: variant ? variant?.content?._rawShopify?.price : content?._rawShopify?.defaultVariant?.price,
                        list: window.location.pathname, // Not required for dl_view_cart; The list the product is displayed in
                        product_id: content?._rawShopify?.productId.toString(), // The product_id
                        variant_id: variant ? variant?.content?._rawShopify?.variantId.toString() : content?._rawShopify?.defaultVariant?.variantId.toString(),// id or variant_id
                        image: content?.main?.basic?.productImages[0]?.asset?.small?.srcWebp?.replace('https:', '') ?? content?.main?.basic?.productImages[0]?.asset?.fluid?.src?.replace('https:', ''),
                        inventory: 'Not tracked',
                        compare_at_price: "0.00",
                    }
                ] // See the products array below
            }
        },
      }
    
    window.ElevarDataLayer.push({...data});
}