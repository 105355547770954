import React, { useRef, useEffect } from "react"
import Img from "gatsby-image"
import useBreakpoint from "~utils/useBreakpoint"
import formatColors from "~utils/formatColors"
import { useCart } from "~context/siteContext"

import Section from "~components/global/section"
import AddToCart from "~components/cart/addToCart"
import Ticker from "~components/modules/ticker"
import ProductImages from "~components/product/productImages"
import ProductVariants from "~components/product/productVariants"
import ProductMeta from "~components/product/productMeta"
import RenderModule from "~components/renderModule"
import { dlViewItem } from "../../utils/dlFunctions"

const ExtraProduct = ({ productData, variantData }) => {

  const { mobile, tablet } = useBreakpoint()
  const addRef = useRef()
  const detailRef = useRef()
  const { addItem, cartLoaded } = useCart()

  // this function is to check basic product data for overrides at a variant level
  // there is probably a much neater way to write this
  const check = (key, empty) => {
    if(variantBasic
      && variantBasic[key]
      && (typeof variantBasic[key] !== 'object' || variantBasic[key].length > 0 || !Array.isArray(variantBasic[key]))){
      return variantBasic[key]
    }
    else if (productBasic && productBasic[key]) {
      return productBasic[key]
    }
    return empty
  }

  const productBasic = productData.main.basic
  const variantBasic = variantData.main.basic

  const addToCart = options => {
    const item = {
      _rawShopify: variantData._rawShopify,
      vId: variantData._rawShopify.variantId,
      title: productData.main.title,
      subTitle: variantData._rawShopify.variantTitle,
      fluid: images.length > 0 ? images[0].asset.fluid : null,
      subscribe: options.frequency > 0,
      price: variantData._rawShopify.price,
      subPrice: null,
      qty: options.qty,
      frequency: options.frequency,
      sellingPlanId: options.sellingPlanId,
      unit: options.frequencyUnit,
    }
    addItem(item, true)
    // It's in the cart 🎉
  }

  const productColorData = {
    primaryColor: check('primaryColor'),
    secondaryColor: check('secondaryColor'),
    primaryColorOverride: check('primaryColorOverride'),
    secondaryColorOverride: check('secondaryColorOverride'),
    highlightColor: check('highlightColor'),
  }

  const productColors = formatColors(productColorData)

  let images = check('productImages', [])

  useEffect(() => {
    if(cartLoaded){
      dlViewItem({...productData}, 'Extra', {content: variantData})
    }
  }, [cartLoaded])

  return(
    <>
      
      <Section name="product-title">
        <div className="product-title__title">
          <h1 className="l-sans">{productData.main.title}</h1>
        </div>
      </Section>
      <Section name="product-info">
        <ProductImages title={check('packageDescription', '')}>
          {images.map(image => (
            <Img fluid={image.asset.fluid} />
          ))}
        </ProductImages>
        <ProductVariants
          varTitle={productData.main.variantTitle}
          currentVariant={variantData}
          variants={productData.main.variants}
          productSlug={productData.main.slug.current}
          currentTitle={variantData._rawShopify.variantTitle}
        />
        <div className="product-meta">
          <ProductMeta title={variantData._rawShopify.variantTitle !== "Default Title" ? variantData._rawShopify.variantTitle : productData.main.title} desc={check('tagline', null)}>
            <div className="product-meta__summary">
              <ul className="dashed">
                {check('description', []).map(point => (
                  <li>{point}</li>
                ))}
              </ul>
            </div>
          </ProductMeta>
          <div className="product-meta__add-to-cart">
          {[].includes(variantData._rawShopify.variantId) ?
              <h2>Sold out</h2>
            :
              <AddToCart
                sub={false}
                types={false}
                price={variantData._rawShopify.price}
              showUnits={check('unitMessage', false)}
              unit={check('unitName', false)}
              multiplier={check('itemsPerUnit', false)}
              onAddToCart={addToCart}/>
          }
          </div>
        </div>
      </Section>
      <div className="product-flexible-content" ref={detailRef}>
        <Ticker text="Subscribe to keep your pantry full" bg={productColors.safe[0]} textColor={productColors.safe[1]}>
          {check('tagline', null)}
        </Ticker>
        {productData.main.modules && productData.main.modules.map(m => (
            <RenderModule
              key={m._key}
              type={m._type}
              m={m}
              productColors={productColors}
              // scrollToAdd={scrollToAdd}
              productTitle={productData.main.title}
            />
          ))}
      </div>
    </>
  )
}

export default ExtraProduct
